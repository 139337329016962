import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useRef, useState } from 'react'
import { Breadcrumb, Button } from 'react-bootstrap'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { showNotification } from '../../service/showNotification'
import AssetsTable from '../../components/AssetsTable'
import { uploadAssets } from '../../service/api'
import { ButtonLoader } from '../../components/ButtonLoader'
import { config } from "../../configs/index"
import useLocalStorage from '../../hooks/useLocalStorage'
const AssetPage = () => {
    const fileInputRef = useRef();
    const [isLoading, setIsLoading] = useState(false)
    const [isNewUploaded, setIsNewUploaded] = useState(false)
    const [token, _] = useLocalStorage("token")
    const [searchTerm, setSearchTerm] = useState("")
    const [totalAssets, setTotalAssets] = useState()

    const handleUpload = async (e) => {
        try {
            e.preventDefault();
            setIsLoading(true)
            const file = e.target?.files?.[0];

            const data = await uploadAssets(file);
            console.log(data.data.success);
            if (data.data.success) {
                showNotification("success", "Assets uploaded successfully");
            }
            setIsNewUploaded(!isNewUploaded)
        } catch (error) {
            console.log("error", error.response.data.message);
            showNotification("error", error?.response?.data?.message);
        }
        finally {
            setIsLoading(false)
            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
        }
    };

    const handleAssetExport = async()=>{
        try {
            const response = await fetch(`${config.REACT_APP_BASE_URL}/api/admin/export-assets`, {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${token}`
              }
            });
      
            if (response.ok) {
              const blob = await response.blob();
              const link = document.createElement('a');
              link.href = URL.createObjectURL(blob);
              link.download = 'Assets.xlsx';
              link.click(); 
              URL.revokeObjectURL(link.href);
            } else {
              console.error('Failed to generate the Excel file');
            }
          } catch (error) {
            console.error('Error during export:', error);
          }
    }

    return (
        <>
            <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4 mx-3">
                <div className="d-block mb-4 mb-xl-0">
                    <Breadcrumb
                        className="d-none d-md-inline-block "
                        listProps={{
                            className: "breadcrumb-dark breadcrumb-transparent ",
                        }}
                    >
                        <Breadcrumb.Item>
                            <FontAwesomeIcon icon={faHome} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Assets</Breadcrumb.Item>
                    </Breadcrumb>
                    <h4>Assets <span style={{opacity: .7, fontSize: "1.2rem"}}>(Total Assets: {totalAssets})</span></h4>
                </div>
                <div className="search-container">
                    <input
                      type="string"
                      className="mx-2"
                      placeholder='Deal Name or Asset ID'
                      style={{padding: "10px 8px"}}
                      onChange={(e)=>setSearchTerm(e.target.value)}
                    />

                    <Button
                        className="mx-2"
                        onClick={handleAssetExport}
                    >
                        {isLoading && <ButtonLoader show={true} />}
                        {!isLoading && "Export"}
                    </Button>
                    <Button
                        className="mx-2"
                        onClick={() => fileInputRef.current.click()}
                    >
                        {isLoading && <ButtonLoader show={true} />}
                        {!isLoading && "Upload"}
                    </Button>
                    <input
                        onChange={handleUpload}
                        multiple={false}
                        ref={fileInputRef}
                        type="file"
                        hidden
                    />
                </div>
            </div>

            <AssetsTable isNewUploaded={isNewUploaded} searchTerm={searchTerm} setTotalAssets={setTotalAssets}/>

        </>
    )
}

export default AssetPage